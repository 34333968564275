import { Paper, PaperProps, Typography, TypographyProps } from '@mui/material';
import { QueryMapping } from 'src/hooks/use-breakpoint';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => {
  return {
    isComming: {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,

      borderTop: `1px solid ${theme.palette.divider}`,

      padding: theme.spacing(2.5),
      [QueryMapping.mobile]: { borderTop: 'none' },
    },
  };
});

export const SomeThingGoneWrong: React.FCC<
  PaperProps & { titleProps?: TypographyProps }
> = ({ children, titleProps, className, ...rest }) => {
  const { classes, cx } = useStyles();

  return (
    <Paper className={cx(classes.isComming, className)} {...rest}>
      <Typography {...titleProps} color="primary" align="center">
        {children}
      </Typography>
    </Paper>
  );
};
