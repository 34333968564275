import { useAtomValue } from 'jotai';
import _ from 'lodash';
import { betSourcesAtom } from 'src/store/settings';
import { excludeBetSource } from 'src/utils/helpers/fixture';
import { ProducerId } from 'src/utils/types/outcome';
import { BetSource } from 'src/utils/types/sportsbook';

// returns connection status from bookmaker
export const useBetSource = (
  source: string | BetSource | undefined,
  producerId: string | number | ProducerId | undefined,
) => {
  const betSources = useAtomValue(betSourcesAtom);

  if (!producerId) return false;

  if (excludeBetSource(source, producerId)) {
    return true;
  }

  const _status = _.chain(betSources)
    .find((o) => +o.producer === +producerId && o.betSource === source)
    .get(['subscribed'], 1)
    .value();

  return +_status === 1;
};
