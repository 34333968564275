import { QueryMapping } from 'src/hooks/use-breakpoint';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => {
  return {
    title: {
      display: 'flex',
      alignItems: 'center',
      margin: theme.spacing(-1, 0, 1),
      padding: theme.spacing(1, 0),
      pointerEvents: 'none',

      [QueryMapping.mobile]: {
        cursor: 'pointer',
        pointerEvents: 'auto',
      },

      '& > *': { fontWeight: 700 },
      '& > *:not(:last-child)': {
        marginRight: theme.spacing(1),
      },
      '& > img': {
        flexShrink: 0,
        width: 16,
        height: 16,
      },

      '& > .grow': {
        flexGrow: 1,
      },
    },
    arrow: {
      transform: 'rotate(0deg)',
      transition: theme.transitions.create(['transform']),

      '&.rotate': { transform: 'rotate(180deg)' },
      display: 'none',

      [QueryMapping.mobile]: {
        display: 'inline-block',
      },
    },

    isComming: {
      padding: theme.spacing(2.5),
    },
  };
});
