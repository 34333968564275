import { Trans } from '@lingui/macro';
import { ButtonBase, ButtonBaseProps, Tooltip } from '@mui/material';
import React, { useMemo } from 'react';
import { OddsArrow } from 'src/components/core/odds-arrow';
import { useBetSource } from 'src/hooks/use-bet-source';
import { BrMarketStatus } from 'src/utils/constants/common-constant';
import { hideTooltip, toStartCase } from 'src/utils/helpers/fixture';
import { BaseEvent, Nullable } from 'src/utils/types/event';
import { BaseOutcome, OutcomeActive } from 'src/utils/types/outcome';
import { useOutcome } from '../hooks/use-outcome';
import { useStyles } from './styles';

interface BetGameButtonProps extends ButtonBaseProps {
  data?: Nullable<BaseOutcome>;
  fixture?: BaseEvent;
  isLive?: boolean;
  isSuspended?: boolean;
}

export const BetGameButton: React.FCC<BetGameButtonProps> = ({
  data,
  fixture,
  isLive,
  ...props
}) => {
  const { classes, cx } = useStyles();
  const { isSelected, addToCart, odds } = useOutcome(data, fixture);

  const connected = useBetSource(data?.source, data?.producerId);

  const hideToolTip = hideTooltip(fixture?.eventId, data?.marketSpecifiers);

  const isActive =
    connected &&
    data?.marketStatus === BrMarketStatus.ACTIVE &&
    data?.outcomeActive === OutcomeActive.ACTIVE;

  const __outcome_name = toStartCase(data?.outcomeGroupName, data?.outcomeName);

  const tooltip = useMemo(() => {
    if (hideToolTip || !isActive) {
      return '';
    }

    if (__outcome_name.includes('Draw')) {
      return __outcome_name;
    }

    return (
      <Trans id="sportsbook.outcome.tool_tip">{__outcome_name} to Win</Trans>
    );
  }, [hideToolTip, __outcome_name, isActive]);

  let node: React.ReactNode = null;
  if (isActive && hideToolTip) {
    node = (
      <>
        {/* three dot in future tab */}
        {data.outcomeGroupName && (
          <span className={classes.threeDot}>
            {toStartCase(data.outcomeGroupName)}
          </span>
        )}
        <span className={cx({ [classes.threeDot]: !data.outcomeGroupName })}>
          {toStartCase(data.outcomeName)}
        </span>
      </>
    );
  }

  return (
    <Tooltip classes={{ tooltip: classes.tooltip }} title={tooltip}>
      <ButtonBase
        {...props}
        disabled={!isActive}
        onClick={addToCart}
        className={cx(classes.odd, {
          [classes.selected]: isSelected,
          [classes.disabled]: !isActive,
          [classes.spaceBetween]: hideToolTip,
        })}>
        {node}

        {isActive ? (
          <div>
            <span className={classes.odds}>{odds}</span>
            <OddsArrow odds={data.currentOdd} />
          </div>
        ) : (
          <Trans id="sportsbook.outcome.suspended">Suspended</Trans>
        )}
      </ButtonBase>
    </Tooltip>
  );
};
