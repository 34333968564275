import { useAtomValue } from 'jotai';
import _ from 'lodash';
import { useMemo } from 'react';
import { categoriesAtom } from 'src/store/casino';
import { sportAtom } from 'src/store/sport';
import { MySport } from 'src/utils/types/sport';
import { useCasinoParams, useMetaParams } from './use-meta-params';

export const useSportFinder = (params: { slug?: string; sportId?: string }) => {
  const all = useAtomValue(sportAtom);

  if (!params.slug && !params.sportId) return null;

  const finder = (x: MySport) =>
    x.sportId === params.sportId || x.slug === params.slug;

  return all.sport.find(finder) || all.eSport.find(finder) || null;
};

export const useCurrentSport = () => {
  const { slug } = useMetaParams();
  return useSportFinder({ slug });
};

export const useCurrentCategory = () => {
  const { category: categoryName } = useCasinoParams();
  const total = useAtomValue(categoriesAtom);
  const val = _.find(total, { categoryName });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => val, [val?.categoryName]);
};
