import { useMemo } from 'react';
import { Paths } from 'src/utils/constants/path';
import { matchPath } from 'src/utils/libs/react-router-dom';
import { SportTypeEnum } from 'src/utils/types/sport';
import { useMyPathname } from './use-locale';
import { useQuery } from './use-query';

export interface MetaParams {
  sportType?: SportTypeEnum;
  slug?: string;
  eventId?: string;
}

export function useMetaParams() {
  const queries = useQuery(['sportType', 'slug', 'eventId']);

  return useMemo(
    () => queries,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queries.sportType, queries.slug, queries.eventId],
  ) as MetaParams;
}

export interface CasinoParams {
  category?: string;
  gameId?: string;
}

export function useCasinoParams(): CasinoParams {
  const queries = useQuery(['category', 'gameId']);

  return useMemo(
    () => queries,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queries.category, queries.gameId],
  );
}

export function useLeftNavHidden(type?: string) {
  const { pathname } = useMyPathname();

  return useMemo(() => {
    const matched = matchPath(pathname, {
      path: Paths.MetaSport,
    });
    const first = matched?.params?.sportType;
    const second = matched?.params?.slug;

    return {
      hidden:
        first &&
        [
          SportTypeEnum.Sport,
          SportTypeEnum.ESport,
          SportTypeEnum.Casino,
        ].includes(first) &&
        first !== type,
      first,
      second,
      isLobby: second === 'lobby',
      isLive: second === 'live',
    };
  }, [type, pathname]);
}
