import { Box, Skeleton } from '@mui/material';

const FixtureLoading = () => {
  return (
    <>
      <Box mt={2}>
        <Box sx={{ marginBottom: '20px' }}>
          <Skeleton
            animation="wave"
            variant="text"
            width="100px"
            sx={{
              transform: 'scale(1, 1) !important',
            }}
          />
        </Box>
        <Skeleton
          height={200}
          sx={{
            transform: 'scale(1, 1) !important',
          }}
        />
      </Box>
      <Box sx={{ margin: '20px 0' }}>
        <Box sx={{ marginBottom: '20px' }}>
          <Skeleton
            animation="wave"
            variant="text"
            width="100px"
            sx={{
              transform: 'scale(1, 1) !important',
            }}
          />
        </Box>
        <Skeleton
          height={200}
          sx={{
            transform: 'scale(1, 1) !important',
          }}
        />
      </Box>
    </>
  );
};

export default FixtureLoading;
