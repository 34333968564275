import { darken } from '@mui/material';
import { mainFont } from 'src/assets/styles/fonts';
import { QueryMapping } from 'src/hooks/use-breakpoint';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => {
  const mobile = QueryMapping.mobile;

  return {
    odd: {
      width: '100%',
      padding: theme.spacing(1.5),
      border: `1px solid ${theme.palette.common.black}`,
      backgroundColor: theme.palette.common.black,
      ...theme.shape,
      height: 42,
      fontSize: 14,
      lineHeight: '18px',
      display: 'flex',
      alignItems: 'center',
      color: theme.custom.content[2],
      fontFamily: mainFont.style.fontFamily,
      '& *': {
        display: 'inline-block',
        whiteSpace: 'nowrap',

        '&:not(:last-child)': {
          marginRight: 8,
        },
      },
      '& img': {
        flexShrink: 0,
      },
      '&:hover': {
        backgroundColor: darken(theme.palette.common.black, 0.4),
      },
    },
    threeDot: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      marginRight: '0.25em !important',
    },

    spaceBetween: {
      [mobile]: {
        '& > :last-child': {
          flex: 1,
          textAlign: 'right',
        },
      },
    },

    selected: {
      borderColor: theme.palette.primary.main,
    },

    tooltip: {
      background: theme.custom.bg[2],
      color: theme.custom.content[2],
      border: `1px solid ${theme.palette.divider}`,
      fontSize: 12,
      lineHeight: 'calc(12px * 1.5)',
      maxWidth: 250,
    },

    odds: {
      color: theme.custom.content[1],
    },

    disabled: {
      width: '100%',
      height: 42,
      padding: theme.spacing(1.5),
      color: theme.custom.content[3],
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: 3,
    },
  };
});
