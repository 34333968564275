import _ from 'lodash';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

interface SBLayoutState {
  nation: boolean;
  seasons: boolean[];
}

interface SBLayoutFunction {
  toggleNation: (next?: boolean) => any;
  toggleSeason: (index?: number, next?: boolean) => any;
}

type SBLayoutInterface = SBLayoutState & SBLayoutFunction;

const SBLayoutContext = createContext<SBLayoutInterface | undefined>(undefined);

export const useSBLayout = (index: number = -1) => {
  const values = useContext(SBLayoutContext);

  const { toggleSeason: _toggleSeason, seasons, ...rest } = values || {};

  const toggleSeason = useCallback(
    (next: boolean) => {
      _toggleSeason?.(index, next);
    },
    [index, _toggleSeason],
  );

  return {
    ...rest,
    season: _.get(seasons, [index], undefined),
    toggleSeason,
  };
};

interface SBLayoutProviderProps {
  defaultValues?: Partial<SBLayoutState>;
}

function toggle(state: boolean, next?: boolean) {
  return !_.isNil(next) ? next : !state;
}

export const SBLayoutProvider: React.FCC<SBLayoutProviderProps> = ({
  children,
  defaultValues,
}) => {
  const [values, setValues] = useState({
    nation: defaultValues?.nation ?? false,
    seasons: defaultValues?.seasons ?? ([] as boolean[]),
  });

  const prev = defaultValues?.seasons?.length || 0;

  useEffect(() => {
    if (values.seasons.length < prev) {
      // @ts-ignore
      setValues(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prev]);

  const toggleNation = useCallback((next?: boolean) => {
    setValues((prev) => ({ ...prev, nation: toggle(prev.nation, next) }));
  }, []);

  const toggleSeason = useCallback((index?: number, next?: boolean) => {
    if (_.isUndefined(index) || index === -1) return;

    setValues((prev) => ({
      ...prev,
      seasons: prev.seasons.map((open, i) => {
        if (i === index) {
          return toggle(open, next);
        }
        return open;
      }),
    }));
  }, []);

  return (
    <SBLayoutContext.Provider
      value={{
        ...values,
        toggleNation,
        toggleSeason,
      }}>
      {children}
    </SBLayoutContext.Provider>
  );
};
