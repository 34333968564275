import { darken } from '@mui/material';
import { QueryMapping } from 'src/hooks/use-breakpoint';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => {
  const mobile = QueryMapping.mobile;
  return {
    root: {
      padding: theme.spacing(2),
      borderColor: theme.palette.divider,
      borderWidth: 1,
      borderTopStyle: 'solid',

      display: 'flex',
      flexFlow: 'wrap row',

      '& .mobile': {
        display: 'none',
        [mobile]: { display: 'flex' },
      },

      '& .desktop': {
        [mobile]: { display: 'none' },
      },

      [mobile]: {
        padding: '16px 16px 0px',
        borderTopStyle: 'none',

        paddingRight: theme.spacing(2),
        '&:first-child': {
          borderTop: 'none',
        },

        '&:last-child': {
          paddingBottom: '16px',
          borderTop: 'none',
        },
      },

      [`& + .outright`]: {
        borderTopStyle: 'solid',
      },
    },
    outright: {
      '&:first-child': { borderTopStyle: 'none !important' as 'none' },
    },

    moreBets: {
      color: theme.custom.content[2],
      textAlign: 'center',
      marginLeft: -4,
      height: '42px !important',
      '&:hover': {
        backgroundColor: 'inherit',
      },

      [mobile]: {
        marginLeft: 0,
        backgroundColor: theme.palette.common.black,
        padding: theme.spacing(1.5),
        '&:hover': {
          [mobile]: {
            backgroundColor: darken(theme.palette.common.black, 0.4),
          },
        },
      },
    },
  };
});
